$(function() {

    // validator base settings
    $.validator.setDefaults({
        errorPlacement: function (error, element) {
            var name = element.attr('name');
            var errorSelector = '.validation_error_message[for="' + name + '"]';
            var $element = $(errorSelector);
            if ($element.length) {
                $(errorSelector).html(error.html());
            } else {
                error.insertBefore(element);
            }
        }
    });


    // Initialize form validation on the registration form.
    // It has the name attribute "registration"
    $("form[id='appointmentForm']").validate({
        // Specify validation rules
        rules: {
            first_name: "required",
            last_name: "required",
            email: {
                required: true,
                email: true
            },
            email_confirmation: {
                required: true,
                email: true
            },
            phone: {
                required: true,
                minlength: 8
            },
            agree1: "required",
            agree2: "required"
        },
        messages: {
            first_name: "A keresztnév megadása kötelező",
            last_name: "A vezetéknév megadása kötelező",
            email: {
                required: "Az email cím megadása kötelező.",
                email: "Az email cím formátuma nem megfelelő."
            },
            email_confirmation: {
                required: "Az email cím megadása kötelező.",
                email: "Az email cím formátuma nem megfelelő."
            },
            phone: {
                required: "A telefonszám megadása kötelező",
                minlength: "A telefonszámnak legalább 8 karakter hosszúságúnak kell lennie."
            },
            agree1: "Be kell pipálni a jelölőnégyzetet.",
            agree2: "Be kell pipálni a jelölőnégyzetet."
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid

        submitHandler: function(form) {
            // do other things for a valid form
            form.submit();
        }

    });
});

$(document).ready(function () {

        /*
        /*****************
        // validate.js



        // form validation
        $("form[id='mainForm']").validate({
            // Specify validation rules
            rules: {
                name: "required",
                email: {
                    required: true,
                    email: true
                },
                agree: "required"
            },
            messages: {
                name: "A Név megadása kötelező",
                email: {
                    required: "Az email cím megadása kötelező.",
                    email: "Az email cím formátuma nem megfelelő."
                },
                agree: "Be kell pipálni a jelölőnégyzetet."
            },
            // Make sure the form is submitted to the destination defined
            // in the "action" attribute of the form when valid

            submitHandler: function(form) {
                // do other things for a valid form
                form.submit();
            }
        });
        */

    });
